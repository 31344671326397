import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ComponentProps, useEffect, useState } from 'react';

import {
  getCurrentBlockHeight,
  getLeaderboardData,
  getLeaderboardWeeks,
  LeaderboardWeeksData
} from '../../../services/utils';
import LeaderboardCard from './LeaderboardCard';
import LeaderboardEntry, { LeaderboardEntryProps } from './LeaderboardEntry';
import LeaderboardPagination from './LeaderboardPagination';

const SearchIcon = (
  <svg viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.6"
      d="M7.70789 2.57146C8.64928 2.57003 9.57288 2.82854 10.3773 3.31861C11.1817 3.80868 11.8359 4.51138 12.268 5.34958C12.7001 6.18778 12.8934 7.12907 12.8268 8.07016C12.7602 9.01125 12.4361 9.91575 11.8902 10.6844L11.3835 11.398L10.6714 11.9058C10.013 12.3734 9.25385 12.6787 8.45556 12.7969C7.65727 12.9151 6.84241 12.8429 6.07722 12.5861C5.31203 12.3293 4.61809 11.8952 4.0518 11.3191C3.48551 10.7429 3.06286 10.041 2.81819 9.27035C2.57351 8.49971 2.51373 7.68208 2.64369 6.88392C2.77366 6.08576 3.0897 5.3296 3.56613 4.67688C4.04257 4.02416 4.66595 3.49331 5.38562 3.12748C6.10529 2.76164 6.90093 2.57114 7.70789 2.57146ZM7.70789 6.95274e-06C6.49608 -0.00162341 5.30101 0.283498 4.21992 0.83217C3.13883 1.38084 2.20227 2.17756 1.48646 3.15749C0.770644 4.13743 0.295798 5.27289 0.100561 6.47148C-0.0946764 7.67006 -0.00478837 8.89791 0.36291 10.0551C0.730609 11.2123 1.36573 12.2662 2.21659 13.1309C3.06744 13.9956 4.11 14.6468 5.25942 15.0315C6.40883 15.4162 7.63263 15.5234 8.83122 15.3446C10.0298 15.1657 11.1693 14.7058 12.1571 14.0022L15.7717 17.6254C16.2708 18.1249 17.0803 18.1249 17.5787 17.6254L17.5973 17.6067C17.724 17.4925 17.8254 17.3529 17.8948 17.1969C17.9641 17.0409 18 16.872 18 16.7013C18 16.5305 17.9641 16.3616 17.8948 16.2056C17.8254 16.0496 17.724 15.91 17.5973 15.7958L13.982 12.1733C14.8005 11.0199 15.2863 9.66293 15.3861 8.25116C15.4859 6.83939 15.1958 5.42735 14.5477 4.16988C13.8996 2.91242 12.9185 1.8581 11.712 1.12254C10.5054 0.38699 9.12011 -0.00138561 7.70789 6.95274e-06Z"
      fill="inherit"
    />
  </svg>
);

const PER_PAGE_WEEKLY = 20;
const PER_PAGE_GLOBAL = 10;
const PER_PAGE_STREAK = 10;

type LeaderboardTableView = 'weekly' | 'global' | 'streak';
type LeaderboardTableWeek = 'current' | 'previous';

const TabButton = (
  props: Pick<ComponentProps<'button'>, 'className' | 'children' | 'onClick'> & {
    isActive?: boolean;
    moreContrastBorder?: boolean;
  }
) => {
  const { className, isActive, moreContrastBorder, ...safeProps } = props;

  const commonClassnames =
    'border border-l-0 first:border-l first:rounded-tl-full first:rounded-bl-full last:rounded-tr-full last:rounded-br-full px-12 py-8 flex-1 text-center text-white/60 text-12 sm:text-14 leading-3 sm:leading-4 md:leading-5 font-bold';

  const borderClassnames = moreContrastBorder ? 'border-white/10' : 'border-tower-defense-gray-light';

  const activeClassnames = 'td-button-gradient !text-white/100 border-0';

  return (
    <button className={classNames(commonClassnames, borderClassnames, isActive && activeClassnames)} {...safeProps}>
      {safeProps.children}
    </button>
  );
};

const LeaderboardTable = () => {
  const { t } = useTranslation();

  const [tableView, setTableView] = useState<LeaderboardTableView>('global');
  const [tableWeek, setTableWeek] = useState<LeaderboardTableWeek>('current');
  const [weeksData, setWeeksData] = useState<LeaderboardWeeksData | null>(null);

  const [data, setData] = useState<LeaderboardEntryProps[] | null>(null);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [pageData, setPageData] = useState<LeaderboardEntryProps[] | null>();
  const [page, setPage] = useState(0);

  const [perPage, setPerPage] = useState<10 | 20>(20);

  const [searchTerm, setSearchTerm] = useState('');

  const MAX_PAGE = data ? (data.length < perPage ? 1 : Math.ceil(data.length / perPage)) : 1;

  useEffect(() => {
    (async () => {
      setPage(0);
      setIsDataLoading(true);

      const loadPreviousWeekData = tableView === 'weekly' && tableWeek === 'previous';
      const indexerFrequency = tableView === 'weekly' ? 'weekly' : 'global';

      const data = await getLeaderboardData('tower-defense', indexerFrequency, loadPreviousWeekData);

      if (tableView === 'streak') {
        setData([
          ...data.sort((a, b) => {
            if (b.longest_streak === a.longest_streak) {
              return b.current_streak > a.current_streak ? 1 : -1;
            }
            return b.longest_streak > a.longest_streak ? 1 : -1;
          })
        ]);
      } else {
        setData(data);
      }

      setIsDataLoading(false);

      if (tableView === 'weekly' && !weeksData) {
        const blockHeight = await getCurrentBlockHeight();

        if (!!blockHeight) {
          setWeeksData(getLeaderboardWeeks(blockHeight));
        }
      }
    })();
  }, [tableView, tableWeek]);

  useEffect(() => {
    if (!data || isDataLoading) {
      return;
    }

    setPage(0);
    setPageData([...data.slice(0 * perPage, 1 * perPage)]);
  }, [data]);

  const handlePageChange = (page: number) => {
    if (!data || isDataLoading) {
      return;
    }

    const adjustedPage = page + 1;
    const matchingPageData = [...data.slice((adjustedPage - 1) * perPage, adjustedPage * perPage)];

    setPage(page);
    setPageData([...matchingPageData]);

    if (typeof window !== 'undefined') {
      // not using smoothscrolling because when switching between pages with
      // different per-page count the dom height changes and looks weird
      // in conjunction with smoothscrolling
      window.scrollTo({ top: 0 });
    }
  };

  const getTable = () => {
    if (isDataLoading || !data || !pageData) {
      return (
        <div className="mt-24 md:mt-32 lg:mt-56 h-full flex flex-col justify-center flex-1">
          <p className="text-[16px] leading-[24px] font-bold text-center">
            <Trans>Loading NFT leaders...</Trans>
          </p>
        </div>
      );
    }

    if (pageData.length === 0) {
      return (
        <div className="mt-24 md:mt-32 lg:mt-56">
          <p className="text-[16px] leading-[24px]  font-bold text-center">
            <Trans>No Stateful NFTs found</Trans>
          </p>
        </div>
      );
    }

    if (searchTerm !== '') {
      const filteredData = data.filter(
        (entry) =>
          (searchTerm !== '' && `#${entry.token_id}`.indexOf(searchTerm) !== -1) ||
          entry.wallet_address.toLowerCase().indexOf(searchTerm) !== -1
      );

      if (filteredData.length === 0) {
        return (
          <div className="mt-32 md:mt-48 lg:mt-80 flex flex-col items-center">
            <img
              src="/images/td-rankings-no-result.webp"
              className="rounded-full w-[120px] h-[120px]"
              alt="No results illustration"
            />
            <p className="text-[18px] leading-[24px] font-bold text-center mt-16">
              <Trans>No Stateful NFTs found</Trans>
            </p>
            <p className="text-[16px] leading-[20px] text-center mt-8 max-w-[220px] mx-auto">
              <Trans>Try changing your search by typing a different word.</Trans>
            </p>
          </div>
        );
      }

      return (
        <ul className="space-y-4 xs:space-y-8 md:mt-16">
          {filteredData.map((entry, index) => (
            <LeaderboardEntry
              key={index}
              {...entry}
              position={data.findIndex((searchEntry) => searchEntry.token_id === entry.token_id) + 1}
              name={`${t('Paima Volcaneer')} #${entry.token_id}`}
              showStreakData={tableView === 'streak'}
              // showCornerNFTBadge={tableView === 'weekly' || tableView === 'streak'}
              // showRewardsBadge={tableView === 'weekly'}
            />
          ))}
        </ul>
      );
    } else {
      return (
        <div className="flex flex-col flex-1 justify-between">
          <ul className="space-y-4 xs:space-y-8 md:mt-16">
            {pageData.map((entry, index) => {
              const position = data.findIndex((searchEntry) => searchEntry.token_id === entry.token_id) + 1;
              const hasPodiumPosition = index <= 2 && page === 0 && data.length > 3;

              return (
                <LeaderboardEntry
                  key={index}
                  {...entry}
                  position={position}
                  name={`${t('Paima Volcaneer')} #${entry.token_id}`}
                  wrapperClassname={hasPodiumPosition ? 'md:hidden' : ''}
                  // showCornerNFTBadge={tableView === 'weekly' || tableView === 'streak'}
                  // showRewardsBadge={tableView === 'weekly'}
                  showStreakData={tableView === 'streak'}
                />
              );
            })}
          </ul>
          {tableView !== 'weekly' && (
            <div className="mt-24 flex justify-end relative z-30">
              <LeaderboardPagination
                handlePageClick={(page) => handlePageChange(page)}
                pageCount={MAX_PAGE}
                forcedPage={page}
              />
            </div>
          )}
        </div>
      );
    }
  };

  const getLeaderboardCards = () => {
    if (isDataLoading || !data || !pageData || data.length <= 3 || page !== 0) {
      return null;
    }

    return (
      <div className="hidden md:flex gap-[16px] lg:gap-[24px] mb-[32px] mt-[52px] items-end">
        <LeaderboardCard
          {...data[1]}
          position={data.findIndex((searchEntry) => searchEntry.token_id === data[1].token_id) + 1}
          name={`${t('Paima Volcaneer')} #${data[1].token_id}`}
          // showCornerNFTBadge={tableView === 'weekly' || tableView === 'streak'}
          // showRewardsBadge={tableView === 'weekly' || tableView === 'streak'}
          showStreakData={tableView === 'streak'}
        />
        <LeaderboardCard
          {...data[0]}
          position={data.findIndex((searchEntry) => searchEntry.token_id === data[0].token_id) + 1}
          name={`${t('Paima Volcaneer')} #${data[0].token_id}`}
          largeVariant
          // showCornerNFTBadge={tableView === 'weekly' || tableView === 'streak'}
          // showRewardsBadge={tableView === 'weekly' || tableView === 'streak'}
          showStreakData={tableView === 'streak'}
        />
        <LeaderboardCard
          {...data[2]}
          position={data.findIndex((searchEntry) => searchEntry.token_id === data[2].token_id) + 1}
          name={`${t('Paima Volcaneer')} #${data[2].token_id}`}
          // showCornerNFTBadge={tableView === 'weekly' || tableView === 'streak'}
          // showRewardsBadge={tableView === 'weekly' || tableView === 'streak'}
          showStreakData={tableView === 'streak'}
        />
      </div>
    );
  };

  const getTableTitle = () => {
    if (tableView === 'weekly') {
      return t('Weekly Leaderboard');
    }

    if (tableView === 'streak') {
      return t('All-time Streak');
    }

    return t('Top 100');
  };

  return (
    <div>
      <div className="flex items-center justify-center mb-24 relative z-20">
        <div className="w-full md:max-w-[500px] mx-auto flex">
          {/**<TabButton
            onClick={() => {
              setTableView('weekly');
              setPerPage(PER_PAGE_WEEKLY);
            }}
            isActive={tableView === 'weekly'}
          >
            <Trans>Weekly</Trans>
          </TabButton>**/}
          <TabButton
            onClick={() => {
              setTableView('global');
              setPerPage(PER_PAGE_GLOBAL);
            }}
            isActive={tableView === 'global'}
          >
            <Trans>Global</Trans>
          </TabButton>
          <TabButton
            onClick={() => {
              setTableView('streak');
              setPerPage(PER_PAGE_STREAK);
            }}
            isActive={tableView === 'streak'}
          >
            <Trans>All-time Streak</Trans>
          </TabButton>
        </div>
      </div>
      <div className="td-glass-bg flex flex-col rounded-[12px] px-16 py-24 md:p-32 relative z-20 text-white min-h-[700px] xl:min-h-[1100px]">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
          <h1 className="font-bold text-32 md:text-40 mb-16 md:mb-0 flex-1">{getTableTitle()}</h1>
          {(tableView === 'global' || tableView === 'streak') && (
            <div className="w-full relative md:max-w-[380px] td-glass-bg rounded-[16px] pl-8 pr-32">
              <input
                type="text"
                placeholder={t('Search by #ID or Address...')}
                className="bg-transparent px-8 py-4 focus:outline-none w-full "
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                value={searchTerm}
              />
              <button className="w-[18px] h-[18px] absolute top-0 bottom-0 m-auto right-16 fill-white">
                {SearchIcon}
              </button>
            </div>
          )}
          {tableView === 'weekly' && (
            <div className="flex items-center justify-center relative z-20 shrink-0">
              <div className="w-full md:max-w-[360px] md:min-w-[300px] flex">
                <TabButton
                  moreContrastBorder
                  onClick={() => {
                    setTableWeek('current');
                  }}
                  isActive={tableWeek === 'current'}
                >
                  <Trans>Current week</Trans>
                </TabButton>
                <TabButton
                  moreContrastBorder
                  onClick={() => {
                    setTableWeek('previous');
                  }}
                  isActive={tableWeek === 'previous'}
                >
                  <Trans>Previous week</Trans>
                </TabButton>
              </div>
            </div>
          )}
        </div>
        {!!weeksData && tableView === 'weekly' && (
          <div className="mt-16 flex justify-start">
            <div className="grid grid-rows-2 grid-cols-[65px_1fr] gap-y-4 font-semibold">
              <p className="text-white/80">
                <Trans>Starts</Trans>:{' '}
              </p>
              <p>{tableWeek === 'current' ? weeksData.currentWeek.startDate : weeksData.previousWeek.startDate}</p>
              <p className="text-white/80">
                <Trans>Ends</Trans>:{' '}
              </p>
              <p>{tableWeek === 'current' ? weeksData.currentWeek.endDate : weeksData.previousWeek.endDate}</p>
            </div>
          </div>
        )}
        <div className="mt-16 flex flex-col flex-1">
          {getLeaderboardCards()}
          {!isDataLoading && (
            <div className="hidden md:flex md:items-center md:justify-between md:px-12">
              <div className="md:flex md:items-center">
                <div className="md:w-[60px]">
                  <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">
                    <Trans>Rank</Trans>
                  </p>
                </div>
                <div className="md:w-[250px]">
                  <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">
                    <Trans>Stateful NFT</Trans>
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  'grid',
                  {
                    'grid-cols-5': tableView === 'weekly',
                    'grid-cols-4': tableView === 'global',
                    'grid-cols-2': tableView === 'streak'
                  },
                  'mt-12 xs:gap-x-8 xs:gap-y-4 xs:mt-0 xs:ml-8 xs:min-w-[240px] md:min-w-fit md:w-[240px] md:ml-16'
                )}
              >
                {(tableView === 'global' || tableView === 'weekly') && (
                  <>
                    <div className="md:w-[48px] lg:w-[80px]">
                      <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">
                        <Trans>Wins</Trans>
                      </p>
                    </div>
                    <div className="md:w-[48px] lg:w-[80px]">
                      <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">
                        <Trans>Draws</Trans>
                      </p>
                    </div>
                    <div className="md:w-[48px] lg:w-[80px]">
                      <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">
                        <Trans>Losses</Trans>
                      </p>
                    </div>
                    <div className="md:w-[48px] lg:w-[80px]">
                      <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">
                        <Trans>Points</Trans>
                      </p>
                    </div>
                  </>
                )}
                {tableView === 'streak' && (
                  <>
                    <div className="md:w-[120px] lg:w-[140px]">
                      <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold text-center">
                        <Trans>Longest streak</Trans>
                      </p>
                    </div>
                    <div className="md:w-[120px] lg:w-[140px]">
                      <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold text-center">
                        <Trans>Current streak</Trans>
                      </p>
                    </div>
                  </>
                )}
                {tableView === 'weekly' && (
                  <div className="md:w-[48px] lg:w-[80px]">
                    <p className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[24px] font-bold">
                      <Trans>Rewards</Trans>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {getTable()}
        </div>
      </div>
    </div>
  );
};

export default LeaderboardTable;
