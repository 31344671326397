import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import React from 'react';
import ReactPaginate from 'react-paginate';

interface LeaderboardPaginationProps {
  handlePageClick: (page: number) => void;
  pageCount: number;
  forcedPage?: number;
}

const LeaderboardPagination: React.FC<LeaderboardPaginationProps> = ({
  handlePageClick,
  pageCount,
  forcedPage
}: LeaderboardPaginationProps) => {
  return (
    <ReactPaginate
      previousLabel={<ChevronLeftIcon className="h-16 w-16" aria-hidden="true" />}
      nextLabel={<ChevronRightIcon className="h-16 w-16" aria-hidden="true" />}
      breakLabel={<DotsHorizontalIcon className="h-16 w-16" aria-hidden="true" />}
      pageCount={pageCount}
      marginPagesDisplayed={3}
      pageRangeDisplayed={3}
      containerClassName={'flex md:inline-flex md:flex-nowrap justify-center gap-12'}
      onPageChange={(e) => handlePageClick(e.selected)}
      pageClassName={
        'w-[28px] h-[28px] td-glass-bg hover:!bg-tower-defense-yellow flex justify-center items-center rounded text-14 text-white font-semibold font-base text-center p-0'
      }
      previousClassName={'hidden'}
      nextClassName={'hidden'}
      breakClassName={'flex flex justify-center items-center text-tower-defense-yellow'}
      activeClassName={'!bg-tower-defense-yellow'}
      pageLinkClassName={'!no-underline w-full h-full flex justify-center items-center pointer-cursor'}
      forcePage={forcedPage}
    />
  );
};

export default LeaderboardPagination;
