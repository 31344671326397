import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { getLocalizedLink } from '../../../utils/locale';

import FAQ from './FAQ';
import LeaderboardTable from './LeaderboardTable';

const LeaderboardLayout = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="relative bg-[url('/images/td-faction-background.webp')] bg-cover bg-no-repeat bg-center">
        <div className="absolute left-0 right-0 top-0 bottom-0 w-full h-full td-bg-gradient" />
        <div className="container-slim">
          <div className="pt-16 md:pt-32 lg:pt-64 pb-40 md:pb-80">
            <LeaderboardTable />
          </div>
        </div>
      </div>
      <div className="bg-[url('/images/rankings-faq-background.webp')] bg-cover bg-no-repeat bg-center">
        <FAQ
          includeCharacters
          questions={[
            {
              question: t('Where can I play Wrath of the Jungle: Tower Defense?'),
              answer: (
                <Trans
                  i18nKey="To play Tower Defense, go to the website's navbar and click on the 'Play Tower Defense' button. This will take you to the game's main page, where you can begin playing immediately."
                  components={[
                    <a
                      className="underline cursor-pointer"
                      href="https://tower-defense.paimastudios.com/"
                      target="_blank"
                    />
                  ]}
                />
              )
            },
            {
              question: t('What factors are taken into account when calculating my ranking on the leaderboard?'),
              answer: t(
                'Your ranking is based on points accumulated for wins, draws, and losses per your Stateful NFT. Each win grants 2 points, draws grant you 1 point, and losses deduct 0 points. The higher your points, the higher your ranking!'
              )
            },
            {
              question: t('What are Stateful NFTs?'),
              answer: (
                <Trans
                  i18nKey="Stateful NFTs are a new class of NFTs developed by Paima Studios. Unlike traditional NFTs, Stateful NFTs carry game state such as stats or achievements. The Volcaneer NFTs, used in Wrath of the Jungle: Tower Defense, are the first iteration of Stateful NFTs and are an exciting first taste of this brand new technology."
                  components={[
                    <a href="https://youtu.be/oE33ON9qZq4" target="_blank" className="underline cursor-pointer" />
                  ]}
                />
              )
            },
            {
              question: t('Where can I get a Stateful NFT to use in Wrath of the Jungle: Tower Defense?'),
              answer: (
                <Trans
                  i18nKey="The Paima Volcaneers NFT (which can be purchased on this site) are the first collection of Stateful NFTs. There is an unlimited supply of them, released in themed batches, with a low price of 10 mADA each. This makes it trivial to get one for yourself to start rising up to the top of the leaderboards."
                  components={[
                    <a
                      href={getLocalizedLink('https://paimastudios.com/volcaneers', i18n.language)}
                      target="_blank"
                      className="underline cursor-pointer"
                    />
                  ]}
                />
              )
            },
            {
              question: t('How do I use Stateful NFTs in Wrath of the Jungle: Tower Defense?'),
              answer: t(
                "To use your Stateful NFT in game, on the main menu click on the 'Profile' button, select the Volcaneer you want to play with, and then click on the 'Pick' button and submit the transaction."
              )
            },
            {
              question: t('Which NFTs are supported?'),
              answer: t(
                "Wrath of the Jungle: Tower Defense allows you to use your Paima Volcaneers in game. Volcaneers are Paima Studio's first iteration of Stateful NFTs and carry the owner's evolving on-chain gaming record."
              )
            }
          ]}
        />
      </div>
    </>
  );
};

export default LeaderboardLayout;
